@import '~antd/dist/antd.css';

/** {*/
/*box-sizing: border-box;*/
/*}*/
/**:before,*/
/**:after {*/
/*box-sizing: border-box;*/
/*}*/
html,
body {
    height: 100%;
    margin: 0;
}

.ant-advanced-search-form {
    padding: 24px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
}

.ant-advanced-search-form .ant-form-item {
    display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
    flex: 1;
}

.ant-form-item-label > label::after {
    content: ' ';
}

.ant-modal-wrap {
    /*z-index:2000;*/
}

.info-box {
    background: #0059b314 !important;
    border-color: #0059b3 !important;
    padding: 20px;
    color: #0059b3 !important;
    font-size: 14px;
}

.error-box {
    color: #d9364f !important;
    border-color: #d9364f !important;
    padding: 20px;
    background: #d9364f14 !important;
    font-size: 14px;
}

.icon_detail {
    color: #1ea7fd;
    cursor: pointer;
}

.icon_detail:hover {
    background-color: lightblue;
}